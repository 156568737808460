import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Collapse,
  IconButton,
  Paper,
} from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import "./ExpandableTable.scss"
// Estilos personalizados utilizando makeStyles
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
    borderRadius: '5rem',
    
  },
  expandableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  expandIcon: {
    transform: 'rotate(0deg)',
  },
  expandIconOpen: {
    transform: 'rotate(180deg)',
  },
}));

const ExpandableTable = ({
  data,
  numberOfElements,
  pageSize,
  setPageSize,
  columns,
  updatePage,
  ExpandedRowComponent,
  translator,
  key,
  page, setPage, 
  setData = null,
  onFilterChange = null,
}) => {
  const classes = useStyles();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  

  // Manejar la expansión de filas
  const handleExpandClick = (recordKey) => {
    setExpandedRowKeys((prevExpandedRowKeys) =>
      prevExpandedRowKeys.includes(recordKey)
        ? prevExpandedRowKeys.filter((key) => key !== recordKey)
        : [...prevExpandedRowKeys, recordKey]
    );
  };

  // Paginación
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    updatePage(newPage + 1); // Asumiendo que updatePage está basado en 1
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filtrado (si es necesario)
  useEffect(() => {
    if (onFilterChange) {
      onFilterChange();
    }
  }, [onFilterChange]);

  // Actualizar registro (si es necesario)
  const updateRecord = (updatedRecord) => {
    if (setData) {
      setData((prevData) =>
        prevData.map((record) =>
          record[key] === updatedRecord[key] ? updatedRecord : record
        )
      );
    }
  };


  return (
    <>

    <TableContainer component={Paper} className={classes.tableContainer}>
      
      <Table
        className={classes.table}
        aria-label="expandable table"
        stickyHeader
      >
        <TableHead >
          <TableRow>
            <TableCell style={{backgroundColor:"var(--color-primary)", color:"var(--color-primary-txt)", fontSize:"1.1rem", fontWeight:"bold"}} />
            {columns.map((column) => (
              <TableCell style={{backgroundColor:"var(--color-primary)", color:"var(--color-primary-txt)", fontSize:"1.1rem", fontWeight:"bold"}} key={column[key] || column.dataIndex}>
                {column.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data.map((row, index) => {
            const isRowExpanded = expandedRowKeys.includes(index);
            return (
              <React.Fragment key={index}>
                <TableRow
                  id={index}
                  className={
                    isRowExpanded ? classes.expandedRow : classes.expandableRow
                  }
                  hover
                >
                  <TableCell style={{backgroundColor:"var(--color-primary)", color:"var(--color-primary-txt)", fontSize:"1.1rem", display:"flex", justifyContent:"center", height:"3.5rem",fontWeight:"bold"}}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleExpandClick(index)}

                    >
                      {isRowExpanded ? (
                        <KeyboardArrowUp style={{color:"var(--color-primary-txt)"}}/>
                      ) : (
                        <KeyboardArrowDown style={{color:"var(--color-primary-txt)"}}/>
                      )}
                    </IconButton>
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column[key] || column.dataIndex}>
                      {row[column.dataIndex]}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={columns.length + 1}
                  >
                    <Collapse
                      in={isRowExpanded}
                      timeout="auto"
                      unmountOnExit
                    >
                       {ExpandedRowComponent(row)} 
                      {/* <ExpandedRowComponent
                        record={row}
                        t={translator}
                        onUpdateRecord={updateRecord}
                      /> */}
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={numberOfElements}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
      />
    </TableContainer>
    </>
  );
};


const generateColumnsFromData = (data, exclude = []) => {
  if (!data || data.length === 0) {
    return [];
  }

  const firstItem = data[0];
  const columns = Object.keys(firstItem).filter(k => !exclude.includes(k)).map((key) => {
    return {
      title: key.charAt(0).toUpperCase() + key.slice(1), // Capitaliza el título
      dataIndex: key,
      key: key,
    };
  });

  return columns;
};


export {ExpandableTable, generateColumnsFromData};
