import axios from "axios";
import jwt_decode from "jwt-decode";

export async function get_offer_is_block_status(ine_code, userHeader, token) {
    let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: URL_BASE + "offers/get_is_closed/" + ine_code,
    headers: {
      us: userHeader,
      Authorization: token,
      "Content-Type": "*",
      "Access-Control-Allow-Headers": "*",
      Accept: "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      // console.log(error);
      return false;
    });
}

export async function set_offer_is_block_status(ine_code, offer_type, is_closed, userHeader, token) {
    let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: URL_BASE + "offers/set_is_closed/" + ine_code + "/" + offer_type + "/" + is_closed,
    headers: {
      us: userHeader,
      Authorization: token,
      "Content-Type": "*",
      "Access-Control-Allow-Headers": "*",
      Accept: "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      // console.log(error);
      return false;
    });
}


export async function get_offer_history(userHeader, token, ine_code="") {
    let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";
  const params = {}
    if(ine_code != ""){
      params["ine_code"]=ine_code
  }
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    
    url: URL_BASE + "offers/get_offers",
    headers: {
      us: userHeader,
      Authorization: token,
      "Content-Type": "*",
      "Access-Control-Allow-Headers": "*",
      Accept: "*/*",
    },
  };

  return axios
    .request(config , {params:params})
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      // console.log(error);
      return false;
    });
}

export async function get_pdf(ine_code,email, name, price_pvp, price_amiantic, price_original_pvp, price_original_amiantic, userHeader, token) {
  
    let URL_BASE =
    process.env.REACT_APP_API_URL +
    "amiantic/v1.0/api/microservices/comercials/";

  const data = {
    "user_email":email,
    "user_name":name,
    "price_pvp":parseFloat(price_pvp),
    "price_amiantic":parseFloat(price_amiantic),
    "original_price_pvp":parseFloat(price_original_pvp),
    "original_price_amiantic":parseFloat(price_original_amiantic)

    }
  let config = {
    method: "post",
    //maxBodyLength: Infinity,
    data:data ,
    url: URL_BASE + "offers/createPDF_offer/" + ine_code ,
    headers: {
      us: userHeader,
      Authorization: token,
     // "Content-Type": "*",
      "Content-Type": "application/json",
      "Accept": "*/*",
      "Access-Control-Allow-Headers": "*",
      Accept: "*/*",
    },
  };

  return axios
    .request(config)
    .then((response) => {
      let data = response.data;
      return data;
    })
    .catch((error) => {
      // console.log(error);
      return false;
    });
}

